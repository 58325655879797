import { AxiosResponse } from "axios";
import { useAxiosTEQ } from "@/services/axios";
import { HTTP_PRODUCT } from "@/services/enums/httpEnum.ts";
import { ProductListResponse } from "@/stores/product/type.ts";
import { QueryFilter } from "../../../../types/common";

const fetchProductListApi = (
  payload: QueryFilter | object
): Promise<AxiosResponse<ProductListResponse>> => {
  return useAxiosTEQ.get(`${HTTP_PRODUCT.FETCH_PRODUCT_TEMPLATE_LIST}`, {
    params: {
      ...payload,
    },
  });
};
export { fetchProductListApi };
