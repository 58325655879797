import { State } from "@/stores/product/type.ts";

export default {
  productsList: (state: State) => {
    return state.products;
  },
  productsCount: (state: State) => {
    return state.count;
  },
};
