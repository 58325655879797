import {
  RoutePoints,
  TViaPoints,
} from "@/components/ViaPointsPackage/types/types";
import { ORDER_TYPE } from "@/services/enums/commonEnum";
import {
  DirectOrder,
  DirectOrderTrip,
  DIRECTS_ORDER_FIELDS,
} from "@/stores/direct-order/types";
import { checkUTCorNot } from "@/utils/dateFormat.util";
import { formatDateTime } from "@/utils/time.utils";
import { generateId } from "@/utils/utils";
import { addMilliseconds } from "date-fns";
import { cloneDeep } from "lodash";

/**
 * Updates the planned arrival and departure times in the viaPoints array based on a new trip departure date.
 * @param {TViaPoints[]} viaPoints - Array of via points containing the planned arrival and departure times.
 * @param {number} diffWithOriginalTrip - The time difference (in milliseconds) between
 *                               the original trip and the new trip.
 * @returns {TViaPoints[]} Updated array of via points with adjusted times.
 */
const updateDatesInPointsArray = (
  viaPoints: TViaPoints[],
  diffWithOriginalTrip: number
) => {
  for (const point of viaPoints) {
    point.planned_departure_time = new Date(
      new Date(
        checkUTCorNot(point.planned_departure_time as string)
      ).valueOf() + diffWithOriginalTrip
    );

    point.planned_arrival_time = point.planned_arrival_time
      ? new Date(
          new Date(
            checkUTCorNot(point.planned_arrival_time as string)
          ).valueOf() + diffWithOriginalTrip
        )
      : null;

    point.actual_departure_time = new Date(
      new Date(point.actual_departure_time as string).valueOf() +
        diffWithOriginalTrip
    );
  }

  return viaPoints;
};

/**
 * Updates the planned arrival and departure times for the oneway and return trips in the routePoints object.
 * @param {RoutePoints} routePoints - The route points object containing oneway and return trip points.
 * @param {number} diffWithOriginalTrip - The time difference (in milliseconds) between
 *                               the original trip and the new trip.
 * @returns {RoutePoints} The updated route points with adjusted times.
 */
const updateRoutePointDates = (
  routePoints: RoutePoints,
  diffWithOriginalTrip: number
): RoutePoints => {
  const tempUpdatedRoutePoints = cloneDeep(routePoints);
  const updatedRoutePoints = {
    oneway: updateDatesInPointsArray(
      tempUpdatedRoutePoints.oneway,
      diffWithOriginalTrip
    ),
    return:
      routePoints.return.length > 0
        ? updateDatesInPointsArray(
            tempUpdatedRoutePoints.return,
            diffWithOriginalTrip
          )
        : [],
  };
  return updatedRoutePoints;
};

/**
 * Creates a new trip object based on the provided trip data, departure date,
 * and the time difference with the original trip.
 * @param {DirectOrderTrip} trip - The original trip object containing details such as route points,
 *               order type, and bus availability.
 * @param {Date} newTripDepartureDate - The departure date for the new trip.
 * @param {number} diffWithOriginalTrip - The time difference (in milliseconds) between
 *                               the original trip and the new trip.
 * @returns {DirectOrderTrip} A new trip object with updated fields, including a new trip ID,
 *          adjusted route points, and updated departure/arrival dates.
 */
const createNewTrip = (
  trip: DirectOrderTrip,
  newTripDepartureDate: Date,
  diffWithOriginalTrip: number
) => {
  const updatedRoutePoints = updateRoutePointDates(
    trip[DIRECTS_ORDER_FIELDS.ROUTE_POINTS],
    diffWithOriginalTrip
  );

  return {
    ...trip,
    [DIRECTS_ORDER_FIELDS.GEN_TID]: generateId(),
    [DIRECTS_ORDER_FIELDS.ORDER_TYPE]:
      trip[DIRECTS_ORDER_FIELDS.ORDER_TYPE] === ORDER_TYPE.ROUND &&
      trip[DIRECTS_ORDER_FIELDS.BUS_AVAILABILITY]
        ? ORDER_TYPE.ROUND
        : ORDER_TYPE.SINGLE,
    [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]: formatDateTime(
      newTripDepartureDate,
      "yyyy-MM-dd HH:mm"
    ),
    [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: addMilliseconds(
      new Date(
        checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME] as string)
      ),
      diffWithOriginalTrip
    ).toString(),
    [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: updatedRoutePoints,
    [DIRECTS_ORDER_FIELDS.PRICE]: 0,
    [DIRECTS_ORDER_FIELDS.PRICE_EX_VAT]: 0,
    [DIRECTS_ORDER_FIELDS.TRIP_STATUS]: "",
    [DIRECTS_ORDER_FIELDS.TEMPLATE_ID]: trip[DIRECTS_ORDER_FIELDS.TEMPLATE_ID],
  };
};

/**
 * Creates a new copy of the given order with updated trip departure dates
 * based on a provided start date.
 *
 * The function calculates the time difference between the original order's
 * first trip's departure date and the given `startDate`. It then applies this
 * difference to update the departure dates of all trips in the order. A new
 * order object is returned with the updated trips.
 * - The function ensures that each trip is updated to maintain the original
 *   time difference between trips.
 * - The `ID` field is removed from each new trip, and trips are indexed
 *   by their generated transaction ID (`GEN_TID`).
 * - The `checkUTCorNot` function is used to handle date conversions.
 * @param {DirectOrder} order - The original order object containing trip details.
 * @param {Date} startDate - The new start date for adjusting trip departure times.
 * @returns {DirectOrder} A new `DirectOrder` object with updated trip departure dates.
 * @example
 * ```typescript
 * const originalOrder: DirectOrder = {
 *   // ...order details
 * };
 * const newStartDate = new Date('2024-01-01');
 * const updatedOrder = executeCopyOrder(originalOrder, newStartDate);
 * console.log(updatedOrder);
 * ```
 */
export const executeCopyOrder = (order: DirectOrder, startDate: Date) => {
  const trips = order.trip;
  const tripKeys = Object.keys(trips);
  let newTrips = {};

  const oldDepartureDate = new Date(
    checkUTCorNot(
      trips[tripKeys[0]][DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string
    )
  );

  let newTripDepartureDate = new Date(
    `${startDate.toDateString()} ${oldDepartureDate.toTimeString()}`
  );

  const diffWithOriginalTrip =
    newTripDepartureDate.valueOf() - oldDepartureDate.valueOf();

  tripKeys.forEach((key) => {
    newTripDepartureDate = new Date(
      new Date(
        checkUTCorNot(
          trips[key][DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string
        )
      ).valueOf() + diffWithOriginalTrip
    );

    const newTrip: DirectOrderTrip = createNewTrip(
      trips[key],
      newTripDepartureDate,
      diffWithOriginalTrip
    );

    console.log("New trip", newTrip);

    delete newTrip[DIRECTS_ORDER_FIELDS.ID];

    newTrips = {
      ...newTrips,
      [newTrip[DIRECTS_ORDER_FIELDS.GEN_TID]]: newTrip,
    };
  });

  const newOrder: DirectOrder = {
    ...order,
    trip: newTrips,
  };

  return newOrder;
};
