import { DateTimeFormatOptions } from "@intlify/core-base";
import { useUserStore } from "@/stores";
import { formatDateTime, removeTZforISOString } from "./time.utils";

const time_options: DateTimeFormatOptions = {
  day: "numeric",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  weekday: "long",
};

const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (date: Date, join = "-"): string => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join(join);
};

export const convertUTCtoLocaleReadable = (dateTime: string): string => {
  if (!dateTime) return "";
  // UTC time string
  // const utcTimeString = "2023-08-09T12:00:00Z"; // Replace this with your UTC time string
  const utcTimeString = dateTime + "Z";
  // Create a Date object from the UTC time string
  const utcDate = new Date(utcTimeString);

  // should have worked with node v-18 according to mdn
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getTimeZones

  // console.log("-------- 38------", localTimeZone.timeZones[0]);

  const locale = Intl.DateTimeFormat(undefined, {
    timeZone: useUserStore().$state.selectedCompanyInfo.timezone,
  }).resolvedOptions().locale;

  const options = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    timeZone: useUserStore().$state.selectedCompanyInfo.timezone,
  };

  // Create a DateTimeFormat object with the local time zone
  const localFormatter = new Intl.DateTimeFormat(locale, {
    ...time_options,
    ...options,
  });

  const localFormatter2 = new Intl.DateTimeFormat("no-No", {
    ...time_options,
    ...options,
  });

  // Format the UTC Date object in the local time zone
  const localTimeString = localFormatter.format(utcDate).split(/[ ,]+/);

  const localTimeString2 = localFormatter2.format(utcDate).split(" ");

  return `${localTimeString[0]}, ${localTimeString2[1]} ${localTimeString[2]}`;
};

/**
 * Checks if a date string is in UTC format, contains 'T', but does not end with 'Z'.
 *
 * This function verifies whether the input string follows the ISO 8601 format
 * (e.g., `YYYY-MM-DDTHH:MM:SS`) and ensures that it does not have a 'Z'
 * at the end, which would signify UTC time.
 * @param {string} dateString - The date string to validate.
 * @returns {boolean} - Returns `true` if the string is in UTC format, contains 'T',
 * but does not end with 'Z'. Otherwise, returns `false`.
 * @example
 * ```typescript
 * isUTCWithTOnly("2024-10-20T09:00:00");  // true
 * isUTCWithTOnly("2024-10-20T09:00:00Z"); // false
 * isUTCWithTOnly("2024-10-20 09:00:00");  // false
 * ```
 */
export const isUTCWithTOnly = (dateString: string): boolean => {
  // Regex to match UTC string with 'T' but no 'Z' at the end
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/;

  return regex.test(dateString);
};

export const removeSeconds = (dateTime: string) => {
  return dateTime?.replace(/(^\d{4}-\d{2}-\d{2} \d{2}:\d{2}):\d{2}/, "$1");
};

/**
 * Converts a UTC date and time to local time and then localized in oslo date and time string.
 * @param {string | undefined} dateTime - The UTC date and time string to convert.
 * @param {string} locale - The locale to use for formatting the date and time. Defaults to "no-No".
 * @returns The localized date and time string.
 */
export const utcToLocaleDateTime = (dateTime: string | undefined): string => {
  if (!dateTime) return "";
  const user = useUserStore();
  const companyTimezone = user.getSelectedCompanyInfo.timezone;
  const utcTimeString = removeTZforISOString(dateTime) + "Z";
  const utcDate = new Date(utcTimeString);

  const localFormatterForDate = new Intl.DateTimeFormat("en-US", {
    dateStyle: "short",
    timeStyle: "short",
    hour12: false,
    timeZone: companyTimezone,
  });

  const localFormatterForTime = new Intl.DateTimeFormat("no-NO", {
    dateStyle: "short",
    timeStyle: "long",
    hour12: false,
    timeZone: companyTimezone,
  });

  const date = localFormatterForDate.format(utcDate).split(/[ ,]+/)[0];
  const time = localFormatterForTime.format(utcDate).split(/[ ,]+/)[1];

  // Extracting components
  const year = new Date(date).getFullYear();
  const month = String(new Date(date).getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(new Date(date).getDate()).padStart(2, "0");

  // Constructing the formatted date and time string
  return `${year}-${month}-${day} ${time}`;
};

export const checkUTCorNot = (datetime: string | Date) => {
  if (typeof datetime === "string" && isUTCWithTOnly(datetime)) {
    return removeSeconds(utcToLocaleDateTime(datetime));
  }
  return formatDateTime(new Date(datetime), "yyyy-MM-dd HH:mm");
};

export const isValidExcelDate = (value) => {
  return typeof value === "number" && value > 0 && value < 2958465; // Excel's max date (9999-12-31)
};
