import router from "@/router";
import { formatDate } from "@/utils/dateFormat.util";
import { isBefore } from "date-fns";

import {
  localStorageGetItem,
  localStorageKeys,
  localStorageSetItem,
} from "@/controllers/common/localStorage";
import { ref } from "vue";
import { ORDER_STATUS, TRIP_STATUS } from "@/services/enums/commonEnum";

type queryParams = {
  startDate?: string;
  endDate?: string;
  defaultOrg?: string[];
  status?: string;
  searchText?: string;
  invoiced_trip?: string;
};

export default class UriController {
  private static _startDate = new Date();
  private static _year = this._startDate.getFullYear();
  private static _month = this._startDate.getMonth();

  private static _defaultOrgs = ref<string[]>();

  static setDefaultOrg(value: string[]) {
    this._defaultOrgs.value = value;
  }

  static getDefaultOrg() {
    return this._defaultOrgs.value;
  }

  static getDefaultStartDate(): string {
    return formatDate(this._startDate);
  }
  static setQuery(query_obj: queryParams): void {
    try {
      router.replace({
        ...router.currentRoute,
        query: {
          ...router.currentRoute.value.query,
          ...query_obj,
        },
      });
    } catch (error) {
      console.log("setQuery error: ", error);
    }
  }

  static getQuery(): queryParams | null {
    let queryValue = {};
    try {
      queryValue = router.currentRoute.value.query;
      return this._validateQueryStructure(queryValue as queryParams);
    } catch (error) {
      return null;
    }
  }
  static checkRoute() {
    // 1. get existing values from query params
    // 2. validate those queries
    // 3. if validation fails set to default values
    // 5. set the query params

    const currentPath = router.currentRoute.value.path;
    const relevantPaths = ["upcoming-trips", "all-trips", "orders", "products"];

    if (relevantPaths.some((path) => currentPath.includes(path))) {
      const dataFromQuery = this.getQuery();
      if (dataFromQuery) {
        const finalQueryObject = dataFromQuery as queryParams;

        if (Object.keys(finalQueryObject).length > 0) {
          const next7Days = new Date();
          next7Days.setDate(this._startDate.getDate() + 7);
          const queryStartDate = new Date(
            finalQueryObject.startDate as string
          ) as Date;
          const shouldUseStartDate =
            isBefore(
              new Date(formatDate(queryStartDate)),
              new Date(formatDate(this._startDate))
            ) && currentPath.includes("upcoming-trips");
          const startDate = shouldUseStartDate
            ? formatDate(this._startDate)
            : finalQueryObject.startDate;

          const endDate = shouldUseStartDate
            ? formatDate(next7Days)
            : finalQueryObject.endDate;

          const paramObj = {
            startDate,
            endDate,
            defaultOrg: finalQueryObject.defaultOrg,
            status: dataFromQuery?.status,
          };

          localStorageSetItem(
            localStorageKeys.date_query,
            JSON.stringify(paramObj)
          );
          localStorageSetItem(
            localStorageKeys.selected_org,
            JSON.stringify(paramObj.defaultOrg)
          );
          this.setQuery(paramObj);
        }
      }
    }
  }

  static setDateQuery(startDate: string, endDate: string) {
    const dateQuery: queryParams = {
      startDate: formatDate(new Date(startDate)),
      endDate: formatDate(new Date(endDate)),
    };
    localStorageSetItem(localStorageKeys.date_query, JSON.stringify(dateQuery));
    this.setQuery(dateQuery);
  }

  private static _validateQueryStructure(
    queryValue: queryParams
  ): queryParams | null {
    const startDate = this._getValidatedDate(queryValue.startDate);
    const endDate = this._getValidatedDate(queryValue.endDate, "endDate");
    const defaultOrg = this._gerValidatedOrgs(queryValue.defaultOrg);
    const status = this._getValidatedStatus(queryValue?.status);
    const searchText = queryValue.searchText;
    const invoiced_trip = queryValue.invoiced_trip;

    return {
      startDate,
      endDate,
      defaultOrg,
      status,
      searchText,
      invoiced_trip,
    };
  }
  /**
   * Function is validating here
   * @param queryStatus
   * @returns
   */
  private static _getValidatedStatus(queryStatus: string | undefined): string {
    const currentRoute = router.currentRoute.value.path;

    if (currentRoute.includes("orders")) {
      return queryStatus && Object.values(ORDER_STATUS).includes(queryStatus)
        ? queryStatus
        : ORDER_STATUS.QUOTATION;
    }
    if (currentRoute.includes("all-trips")) {
      return queryStatus && Object.values(TRIP_STATUS).includes(queryStatus)
        ? queryStatus
        : ORDER_STATUS.ALL;
    }

    return "";
  }

  private static _getValidatedDate(
    new_date: string | undefined,
    whichDate = "startDate"
  ): string {
    const defaultData = this.getDefaultStartDate();
    if (new_date) {
      return isNaN(Date.parse(new_date)) // case 02
        ? defaultData
        : new_date;
    }

    const localStorageDate = localStorageGetItem(localStorageKeys.date_query);
    if (localStorageDate) {
      const localDateObj = JSON.parse(localStorageDate) as queryParams;
      return localDateObj[whichDate] ?? defaultData;
    }

    const currentRoute = router.currentRoute.value.path;

    if (
      currentRoute.includes("upcoming-trips") &&
      whichDate.includes("endDate")
    ) {
      const next7Days = new Date();
      next7Days.setDate(this._startDate.getDate() + 7);
      return formatDate(next7Days);
    }

    if (currentRoute.includes("all-trips") && whichDate.includes("endDate")) {
      const oneMonthEnd = new Date(this._year, this._month + 1, 0);
      return formatDate(oneMonthEnd);
    }

    if (currentRoute.includes("all-trips")) {
      const oneMonthStart = new Date(this._year, this._month, 1);
      return formatDate(oneMonthStart);
    }

    return defaultData as string;
    // case 01
  }

  private static _gerValidatedOrgs(queryOrgs: string[] | undefined): string[] {
    const defOrg = this.getDefaultOrg();
    if (queryOrgs && defOrg) {
      if (typeof queryOrgs == "string") {
        return [queryOrgs];
      }
      const filteredOrgs = defOrg.filter((item) =>
        queryOrgs.some((org) => org == item)
      );
      return filteredOrgs;
    } else if (queryOrgs) {
      return queryOrgs;
    }
    const localStoredOrgs = localStorageGetItem(localStorageKeys.selected_org);
    if (localStoredOrgs) {
      const parsedOrgs = JSON.parse(localStoredOrgs);
      return parsedOrgs;
    }

    if (defOrg) {
      return defOrg;
    }

    return [];
  }

  static setOrgQuery(orgs: string[]) {
    const query: queryParams = {
      defaultOrg: orgs,
    };
    localStorageSetItem(localStorageKeys.selected_org, JSON.stringify(orgs));
    this.setQuery(query);
  }
}
